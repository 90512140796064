<template>
  <div>
    <Panel
      v-for="(main, index) in mainCategories"
      v-bind:key="main.Id"
      :id="'general_main' + index"
      :title="main"
      :editable="editMode"
      :general="true"
      @blocked="blockChanged(main, $event)"
      @renamed="nameChanged(main, $event)"
    >
      <b-table
        :fields="fields.MainCategories"
        :items="main.Structures"
        outlined
        fixed
        small
        selectable
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
        table-variant="primary"
      >
        <template v-slot:cell(Name)="row">
          <b-row align-v="center">
            <b-col cols="0" no-gutters>
              <b-button
                v-b-toggle="row.item.Id"
                class="ml-3"
                size="sm"
                variant="outline-dark"
              >
                <span class="when-open">
                  <b-icon-chevron-down />
                </span>
                <span class="when-closed">
                  <b-icon-chevron-right />
                </span>
              </b-button>
            </b-col>
            <b-col>
              <b-form-input
                :disabled="row.item.Blocked"
                v-if="editMode"
                v-model="row.item.Name"
                @change="ModifyStructure(row.item, main)"
              />
              <div v-else>{{ row.item.Name }}</div>
            </b-col>
            <b-col v-if="editMode" cols="2">
              <b-icon
                scale="1.5"
                v-b-popover.hover.top="popupGeneralBlocked"
                icon="shield-lock-fill"
                v-if="row.item.GeneralBlocked"
              />
              <b-button
                v-else
                :pressed.sync="row.item.Blocked"
                @click="ModifyStructure(row.item, main)"
                variant="outline-primary"
                size="sm"
                ><b-icon scale="1.4" icon="lock-fill" v-if="row.item.Blocked" />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
              <b-button
                v-if="!row.item.Blocked"
                size="sm"
                @click="DeleteStructure(row.item, main)"
                variant="danger"
                class="rightBlock"
              >
                <b-icon-dash-square />
              </b-button>
            </b-col>
          </b-row>
        </template>

        <template v-slot:row-details="structureInfo">
          <b-collapse :id="structureInfo.item.Id">
            <b-table
              :fields="fields.TaskCategories"
              :items="structureInfo.item.Tasks"
              outlined
              small
              fixed
              select-mode="single"
              show-empty
              table-variant="secondary"
              :emptyText="$t('placeholders.NoInformationAdded')"
            >
              <template v-slot:table-colgroup>
                <col />
                <col class="w-25" />
              </template>
              <template v-if="editMode" v-slot:cell(Description)="row">
                <b-form-textarea
                  :disabled="row.item.Blocked"
                  no-resize
                  :placeholder="$t('placeholders.TaskDescription')"
                  v-model="row.item.Description"
                  @change="ModifyTask(row.item, structureInfo.item, main)"
                />
              </template>
              <template v-slot:row-details="taskInfo">
                <div v-if="editMode">
                  <b-collapse :id="'comment' + taskInfo.item.Id">
                    <hr />
                    <b-form-textarea
                      :placeholder="$t('placeholders.Comment')"
                      no-resize
                      v-model="taskInfo.item.Comment"
                      @change="
                        ModifyTask(taskInfo.item, structureInfo.item, main)
                      "
                    />
                  </b-collapse>
                </div>
                <div v-else>
                  <hr />
                  <mark class="bg-warning" v-if="taskInfo.item.Comment">
                    {{ taskInfo.item.Comment }}
                  </mark>
                </div>
                <hr />

                <!-- Resources -->
                <div class="bg-white d-flex p-2" style="height='5rem'">
                  <h5 class="w-100">
                    {{ $t("fields.Resources") }}
                  </h5>
                </div>
                <b-table
                  :fields="fields.Resources"
                  :items="taskInfo.item.Resources"
                  outlined
                  bordered
                  small
                  fixed
                  select-mode="single"
                  show-empty
                  table-variant="light"
                  :emptyText="$t('placeholders.NoInformationAdded')"
                >
                  <template v-slot:table-colgroup>
                    <col />
                    <col class="w-25" />
                    <col class="w-25" />
                  </template>

                  <template v-slot:cell(Name)="row">
                    <template v-if="editMode">
                      <b-select
                        v-model="row.item.Resource"
                        @change="
                          ModifyResource(
                            row.item,
                            taskInfo.item,
                            structureInfo.item,
                            main
                          )
                        "
                      >
                        <b-select-option
                          v-bind:key="'previous'"
                          :value="row.item.Resource"
                        >
                          {{ row.item.Resource.Name }}
                        </b-select-option>

                        <b-select-option
                          v-for="(resource, index) in resources.filter(
                            (x) =>
                              x.Id !== row.item.Resource.Id &&
                              !taskInfo.item.Resources.some(
                                (e) => x.Id == e.Resource.Id
                              )
                          )"
                          v-bind:key="index"
                          :value="resource"
                        >
                          {{ resource.Name }}
                        </b-select-option>
                      </b-select>
                    </template>
                    <template v-else>
                      {{ row.item.Resource.Name }}
                    </template>
                  </template>
                  <template v-if="editMode" v-slot:cell(Hours)="row">
                    <b-input
                      v-model="row.item.Hours"
                      @change="
                        ModifyResource(
                          row.item,
                          taskInfo.item,
                          structureInfo.item,
                          main
                        )
                      "
                      type="number"
                      min="0.00"
                      max="9999999"
                      :number="true"
                    />
                  </template>
                  <template v-slot:cell(Cost)="row">
                    <i18n-n :value="row.value" format="currency" />
                  </template>
                  <!-- <template v-slot:cell(ExternalCost)="row">
                    <div class="input">
                      <i18n-n :value="row.value" format="currency" />
                    </div>
                    <b-button
                      v-if="editMode"
                      class="rightBlock"
                      size="sm"
                      @click="
                        DeleteResource(
                          row.item,
                          taskInfo.item,
                          structureInfo.item,
                          main
                        )
                      "
                      variant="danger"
                    >
                      <b-icon-person-dash />
                    </b-button>
                  </template> -->

                  <template v-slot:cell(FinalCost)="row">
                    <i18n-n :value="row.value" format="currency" />
                    <b-button
                      v-if="editMode"
                      class="rightBlock"
                      size="sm"
                      @click="
                        DeleteResource(
                          row.item,
                          taskInfo.item,
                          structureInfo.item,
                          main
                        )
                      "
                      variant="danger"
                    >
                      <b-icon-person-dash />
                    </b-button>
                  </template>

                  <!-- <template v-slot:cell(Action)="row">
                    <i18n-n :value="row.value" format="currency" />
                    <b-button
                      class="rightBlock"
                      size="sm"
                      @click="DeleteResource(row.item, taskInfo.item)"
                      variant="danger"
                    >
                      <b-icon-person-dash />
                    </b-button>
                  </template> -->
                </b-table>
                <b-button
                  v-if="editMode"
                  size="sm"
                  @click="AddResource(taskInfo.item, structureInfo.item, main)"
                  variant="success"
                >
                  <b-icon-person-plus />
                  {{ $t("buttons.AddResource") }}
                </b-button>
              </template>
              <template v-slot:cell(Cost)="row">
                <div v-if="editMode">
                  <b-icon
                    scale="1.5"
                    v-b-popover.hover.top="popupGeneralBlocked"
                    icon="shield-lock-fill"
                    v-if="row.item.GeneralBlocked"
                  />
                  <b-button
                    v-else
                    :pressed.sync="row.item.Blocked"
                    @click="ModifyTask(row.item, structureInfo.item, main)"
                    variant="outline-primary"
                    size="sm"
                    ><b-icon
                      scale="1.4"
                      icon="lock-fill"
                      v-if="row.item.Blocked"
                    />
                    <b-icon scale="1.4" icon="unlock-fill" v-else />
                  </b-button>
                  <b-button
                    v-if="!row.item.Blocked"
                    class="rightBlock"
                    size="sm"
                    @click="DeleteTask(row.item, structureInfo.item, main)"
                    variant="danger"
                  >
                    <b-icon-dash-square />
                  </b-button>
                  <b-button
                    v-b-toggle="'comment' + row.item.Id"
                    v-b-tooltip.hover
                    size="sm"
                    variant="primary"
                    class="mr-1 rightBlock"
                    :title="$t('views.subCases.buttons.comment')"
                    ><b-icon-chat-right-text
                  /></b-button>
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    variant="primary"
                    class="mr-1 rightBlock"
                    :title="$t('objects.parameters')"
                    @click="
                      selectParameters(row.item, structureInfo.item, main)
                    "
                    ><b-icon-sliders
                  /></b-button>
                </div>
              </template>
            </b-table>
            <b-button
              v-if="editMode"
              size="sm"
              @click="AddTask(structureInfo.item, main)"
            >
              <b-icon-plus-circle /> {{ $t("views.master-form.addTask") }}
            </b-button>
          </b-collapse>
        </template>
      </b-table>
      <b-button
        v-if="editMode"
        size="sm"
        @click="AddStructure(main)"
        variant="primary"
      >
        <b-icon-plus-circle /> {{ $t("views.master-form.addTaskCategory") }}
      </b-button>

      <b-button
        v-if="editMode && !main.Blocked"
        size="sm"
        @click="DeleteMainCategory(main, mainCategories)"
        variant="danger"
        style="float: right"
      >
        <b-icon-dash-square /> Delete Main Category
      </b-button>
      <hr />
    </Panel>
  </div>
</template>
<script>
import Panel from "@/components/panel";
export default {
  components: {
    Panel,
  },
  props: {
    mainCategories: {
      required: true,
      type: Array,
    },
    resources: {
      required: false,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    modifications: {
      required: true,
      type: Array,
    },
  },
  watch: {
    editMode() {},
  },
  methods: {
    ModifyMainCategory(mainCategory) {
      var item = this.modifications.find((x) => x.Id === mainCategory.Id);

      if (!item) {
        item = {
          Id: mainCategory.Id,
          Name: mainCategory.Name,
          Blocked: mainCategory.Blocked,
          Structures: {
            New: [],
            Modified: [],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Name = mainCategory.Name;
        item.Blocked = mainCategory.Blocked;
        item.Structures = item.Structures || {
          New: [],
          Modified: [],
          Deleted: [],
        };
      }

      return item;
    },

    DeleteMainCategory(mainCategory, mainCategories) {
      const index = mainCategories.indexOf(mainCategory);
      if (index > -1) {
        var item = this.ModifyMainCategory(mainCategory);
        item.Delete = true;

        mainCategories.splice(index, 1);
      }
    },

    AddStructure(mainCategory) {
      var structure = {
        Id: "Structure_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        Tasks: [],
        _showDetails: true,
      };

      var item = this.ModifyMainCategory(mainCategory);
      item.Structures.New.push(structure);

      mainCategory.Structures.push(structure);

      return structure;
    },

    ModifyStructure(structureInfo, mainCategory) {
      var item = this.ModifyMainCategory(mainCategory);
      var existing = item.Structures.Modified.find(
        (x) => x.Id === structureInfo.Id
      );

      if (!existing) {
        existing = {
          Id: structureInfo.Id,
          Blocked: structureInfo.Blocked,
          Name: structureInfo.Name,
          Tasks: {
            New: [],
            Modified: [],
            Deleted: [],
          },
        };
        item.Structures.Modified.push(existing);
      } else {
        existing.Blocked = structureInfo.Blocked;
        existing.Name = structureInfo.Name;
      }

      return existing;
    },

    DeleteStructure(structure, main) {
      const index = main.Structures.indexOf(structure);
      if (index > -1) {
        var item = this.ModifyMainCategory(main);
        item.Structures.Deleted.push({
          Id: structure.Id,
        });
        main.Structures.splice(index, 1);
      }
    },

    AddTask(structureInfo, mainCategory) {
      let newTask = {
        Id: "Task_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Description: "",
        Resources: [],
        Cost: 0,
        ExternalCost: 0,
        Comment: "",
        Hours: 0,
        Parameters: [],
        _showDetails: true,
      };

      var item = this.ModifyStructure(structureInfo, mainCategory);
      item.Tasks.New.push(newTask);

      structureInfo.Tasks.push(newTask);

      return newTask;
    },

    ModifyTask(task, structureInfo, mainCategory) {
      var item = this.ModifyStructure(structureInfo, mainCategory);
      var existing = item.Tasks.Modified.find((x) => x.Id === task.Id);

      if (!existing) {
        existing = {
          Id: task.Id,
          Blocked: task.Blocked,
          SystemBlocked: task.SystemBlocked,
          Description: task.Description,
          Resources: {
            New: [],
            Modified: [],
            Deleted: [],
          },
          Cost: task.Cost,
          ExternalCost: task.ExternalCost,
          Hours: task.Hours,
          Comment: task.Comment,
          Parameters: task.Parameters,
        };
        item.Tasks.Modified.push(existing);
      } else {
        existing.Blocked = task.Blocked;
        existing.SystemBlocked = task.SystemBlocked;
        existing.Description = task.Description;
        existing.Cost = task.Cost;
        existing.ExternalCost = task.ExternalCost;
        existing.Hours = task.Hours;
        existing.Comment = task.Comment;
        existing.Parameters = task.Parameters;

        if (Array.isArray(existing.Resources)) {
          existing.Resources = {
            New: [],
            Modified: [],
            Deleted: [],
          };
        }
      }

      return existing;
    },

    DeleteTask(task, structureInfo, mainCategory) {
      const index = structureInfo.Tasks.indexOf(task);
      if (index > -1) {
        var existing = this.ModifyStructure(structureInfo, mainCategory);
        existing.Tasks.Deleted.push({ Id: task.Id });

        structureInfo.Tasks.splice(index, 1);
      }
    },

    AddResource(taskInfo, structure, main) {
      if (taskInfo.Resources.length < this.resources.length) {
        let newResource = {
          Id: "TaskResource_" + String(Date.now()),
          Hours: 0,
          Resource: {
            Id: "..",
            Name: "",
            HourlyRate: 0,
            ExternalRate: 0,
          },
          Cost: 0,
          ExternalCost: 0,
          FinalCost: 0,
          FinalExternalCost: 0,
        };

        var task = this.ModifyTask(taskInfo, structure, main);
        task.Resources.New.push(newResource);
        taskInfo.Resources.push(newResource);
      } else {
        this.$emit(
          "showmessage",
          this.$t("messages.titles.warning"),
          this.$t("messages.MaxTaskResources")
        );
      }
    },

    ModifyResource(resource, taskInfo, structureInfo, main) {
      var task = this.ModifyTask(taskInfo, structureInfo, main);

      var existing = task.Resources.Modified.find((x) => x.Id == resource.Id);

      if (!existing) {
        task.Resources.Modified.push(resource);
      } else {
        existing.Hours = resource.Hours;
        existing.Resource = resource.Resource;
        existing.Cost = resource.Cost;
        existing.ExternalCost = resource.ExternalCost;
        existing.FinalCost = resource.FinalCost;
        existing.FinalExternalCost = resource.FinalExternalCost;
      }

      this.$emit(
        "resourcechanged",
        resource.Resource,
        resource,
        taskInfo,
        structureInfo,
        main
      );

      this.$emit(
        "hourschanged",
        resource.Hours,
        resource,
        taskInfo,
        structureInfo,
        main
      );
    },

    DeleteResource(resource, taskInfo, structureInfo, main) {
      const index = taskInfo.Resources.indexOf(resource);

      if (index > -1) {
        var existing = this.ModifyTask(taskInfo, structureInfo, main);
        existing.Resources.Deleted.push({ Id: resource.Id });

        taskInfo.Resources.splice(index, 1);
      }

      this.$emit("updateAllResources", taskInfo.Resources);
      this.$emit(
        "hourschanged",
        this.$event,
        resource,
        taskInfo,
        structureInfo,
        main
      );
    },

    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },

    selectParameters(item, structureInfo, mainCategory) {
      this.ModifyTask(item, structureInfo, mainCategory);
      this.$emit("selectedItem", item);
    },

    nameChanged(main, event) {
      main.Name = event;
      this.ModifyMainCategory(main);
    },
    blockChanged(main, event) {
      main.Blocked = event;
      this.ModifyMainCategory(main);
    },
  },
  computed: {
    fields() {
      return {
        MainCategories: [
          {
            key: "Name",
            label: this.$t("fields.MainCategories.Category"),
          },
        ],
        TaskCategories: [
          {
            key: "Description",
            label: this.$t("fields.TaskCategories.Task"),
          },
          {
            key: "Hours",
            label: this.$t("fields.TaskCategories.Hours"),
          },
          {
            key: "Cost",
            label: " ",
          },
        ],
        Resources: [
          {
            key: "Name",
            label: this.$t("fields.TaskCategories.Resource"),
          },
          {
            key: "Hours",
            label: this.$t("fields.TaskCategories.Hours"),
          },
          {
            key: "Cost",
            label: this.$t("fields.TaskCategories.PerPatient"),
          },
          {
            key: "FinalCost",
            label: "€",
          },
        ],
      };
    },
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.blockedInParent"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
