<template>
  <div>
    <category-panel-view
      v-for="(cost, index) in generalCostCategories"
      v-bind:key="index.Id"
      :id="'general_cost_category_view_' + index"
      :title="cost.Name"
    >
      <b-table
        :fields="fields"
        :items="cost.Items"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      />
    </category-panel-view>
    <category-panel-view
      v-for="(cost, index) in costCategories"
      v-bind:key="cost.Id"
      :id="'cost_category_view_' + index"
      :title="cost.Name"
    >
      <b-table
        :fields="fields"
        :items="cost.Items"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      />
    </category-panel-view>
  </div>
</template>
<script>
import CategoryPanelView from "../category-panel-view.vue";

export default {
  components: {
    CategoryPanelView,
  },
  props: {
    costCategories: {
      required: true,
      type: Array,
    },
    generalCostCategories: {
      required: true,
      type: Array,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "Name",
          sortable: false,
          label: this.$t("fields.CostCategories.Item"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
