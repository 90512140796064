<template>
  <b-modal
    id="select-fields-modal"
    scrollable
    :title="$t('fields.Summations.SelectFields')"
    size="lg"
    @shown="onShow"
    @hide="onHide"
    ok-only
  >
    <b-card-header header-border-variant="light" class="mb-2">
      <b-row class="mb-3">
        <b-col cols="3">
          <strong>{{ $t("objects.generalSummations") + ":" }}</strong>
        </b-col>
        <b-col>
          <b-select
            v-if="editMode && summationEdit"
            :value="{
              Id: summ.Id,
              Name:
                summ.Id !== summ.OriginalId ? summ.Name : $t('general.custom'),
            }"
            @change="changedSummation"
          >
            <template #first>
              <b-select-option
                :value="{ Id: summ.OriginalId, Name: $t('general.custom') }"
              >
                {{ $t("general.custom") }}
              </b-select-option>
            </template>
            <b-select-option
              v-for="resource in generalSummations"
              :value="{
                Id: resource.idGeneralSummation.toString(),
                Name: resource.generalSummationName,
              }"
              v-bind:key="resource.idGeneralSummation"
            >
              {{ resource.generalSummationName }}
            </b-select-option>
          </b-select>
          <div v-else>
            {{ summ.Id !== summ.OriginalId ? summ.Name : $t("general.custom") }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="3">
          <strong>{{ $t("general.name") + ":" }}</strong>
        </b-col>
        <b-col>
          <b-input
            v-if="editMode && summ.Id === summ.OriginalId"
            v-model="summ.Name"
            :placeholder="$t('fields.Summations.Name')"
          ></b-input>
          <div v-else>
            {{ summ.Name }}
            <b-icon
              v-if="summ.Id !== summ.OriginalId"
              v-b-popover.hover.top="popupGeneralBlocked"
              icon="shield-lock-fill"
            >
            </b-icon>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="3"
          ><strong>{{ $t("fields.Summations.Value") + ":" }}</strong></b-col
        >
        <b-col cols="4">
          <div v-if="editMode && summationEdit">
            <b-form-select id="StudyTypeSelection" v-model="summ.Surcharge">
              <b-form-select-option
                v-bind:key="-1"
                :value="summ.Surcharge"
                v-if="summ.Surcharge"
              >
                {{ summ.Surcharge.surchargeName }}
              </b-form-select-option>
              <b-form-select-option
                v-for="(type, index) in SurchargeTypes.filter(
                  (x) =>
                    !summ.Surcharge ||
                    x.idSurcharge !== summ.Surcharge.idSurcharge
                )"
                v-bind:key="index"
                :value="type"
              >
                {{ type.surchargeName }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div v-else>
            {{ summ.Surcharge.surchargeName }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4"
          ><strong>{{ $t("fields.Summations.OnlyPercentage") }}:</strong></b-col
        >
        <b-col>
          <b-checkbox
            style="float: left"
            v-model="summ.OnlyPercentage"
            :disabled="!editMode || !summationEdit"
        /></b-col>
        <b-col cols="4"
          ><strong>{{ $t("fields.Summations.PerPatient") }}:</strong></b-col
        >
        <b-col cols="2"
          ><b-checkbox
            style="float: left"
            v-model="summ.PerPatient"
            :disabled="!editMode || !summationEdit"
        /></b-col>
        <b-col cols="4"
          ><strong>{{ $t("fields.Summations.OnlyInternal") }}:</strong></b-col
        >
        <b-col cols="2"
          ><b-checkbox
            style="float: left"
            v-model="summ.OnlyInternal"
            :disabled="!editMode || !summationEdit"
        /></b-col>
      </b-row>
    </b-card-header>
    <b-tabs content-class="mt-3" data-spy="scroll">
      <b-tab :title="$t('fields.Summations.CostCategories')" active>
        <div
          v-for="(costCategory, index) in items.CostCategories"
          v-bind:key="'costCategoryField' + index"
        >
          {{ "- " + costCategory.Name }}
          <b-checkbox
            style="float: right"
            :checked="summ.CostCategories[costCategory.Id] != null"
            :disabled="!editMode || !summationEdit"
            @change="AddCostCategory(costCategory, $event)"
          />
          <hr />
        </div>
        <div
          v-for="(costCategory, index) in generalItems.CostCategories"
          v-bind:key="'generalcostCategoryField' + index"
        >
          {{ "- " + costCategory.Name }}
          <b-checkbox
            style="float: right"
            :checked="summ.CostCategories[costCategory.Id] != null"
            :disabled="!editMode || !summationEdit"
            @change="AddCostCategory(costCategory, $event)"
          />
          <hr />
        </div>
      </b-tab>
      <b-tab :title="$t('fields.Summations.CostItems')">
        <div
          v-for="(costCategory, index) in items.CostCategories.concat(
            generalItems.CostCategories
          )"
          v-bind:key="'costCategoryFieldtemp' + index"
        >
          <div
            v-for="(costItem, costIndex) in costCategory.Items"
            v-bind:key="'costCategoryFieldtemp' + index + '_' + costIndex"
          >
            {{ "- " + costItem.Name }}
            <b-checkbox
              style="float: right"
              :checked="summ.Costs[costItem.Id] != null"
              :disabled="!editMode || !summationEdit"
              @change="AddCostItem(costItem, $event)"
            />
            <hr />
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('fields.Summations.MainCategories')">
        <div
          v-for="(mainCategory, index) in items.MainCategories.concat(
            generalItems.MainCategories
          )"
          v-bind:key="'MainCategoryField' + index"
        >
          {{ "- " + mainCategory.Name }}
          <b-checkbox
            style="float: right"
            :checked="summ.MainCategories[mainCategory.Id] != null"
            :disabled="!editMode || !summationEdit"
            @change="AddMainCategory(mainCategory, $event)"
          />
          <hr />
        </div>
      </b-tab>
      <b-tab :title="$t('fields.Summations.TaskCategories')">
        <div
          v-for="(mainCategory, index) in items.MainCategories.concat(
            generalItems.MainCategories
          )"
          v-bind:key="'MainCategoryFieldTask' + index"
        >
          <div
            v-for="(taskCategory, categoryIndex) in mainCategory.Structures"
            v-bind:key="'MainCategoryFieldTask' + index + '_' + categoryIndex"
          >
            {{ "- " + taskCategory.Name }}
            <b-checkbox
              style="float: right"
              :checked="summ.Structures[taskCategory.Id] != null"
              :disabled="!editMode || !summationEdit"
              @change="AddTaskCategory(taskCategory, $event)"
            />
            <hr />
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('fields.Summations.Tasks')">
        <div
          v-for="(mainCategory, index) in items.MainCategories.concat(
            generalItems.MainCategories
          )"
          v-bind:key="'MainCategoryFieldTaskItems' + index"
        >
          <div
            v-for="(taskCategory, categoryIndex) in mainCategory.Structures"
            v-bind:key="
              'MainCategoryFieldTaskItems' + index + '_' + categoryIndex
            "
          >
            <div
              v-for="(task, taskIndex) in taskCategory.Tasks"
              v-bind:key="
                'MainCategoryFieldTaskItems' +
                index +
                '_' +
                categoryIndex +
                '_' +
                taskIndex
              "
            >
              {{ "- " + task.Description }}
              <b-checkbox
                style="float: right"
                :checked="summ.Tasks[task.Id]"
                :disabled="!editMode || !summationEdit"
                @change="AddTask(task, $event)"
              />
              <hr />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('fields.Summations.Summations')">
        <div
          v-for="(summationItem, index) in items.Summations.concat(
            generalItems.Summations
          ).filter((x) => x.Id != summ.Id)"
          v-bind:key="'SummationsField' + index"
        >
          {{ "- " + summationItem.Name }}
          <b-checkbox
            style="float: right"
            :checked="summ.Summations[summationItem.Id] != null"
            :disabled="!editMode || !summationEdit"
            @change="AddSummation(summationItem, $event)"
          />
          <hr />
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    items: {
      required: true,
      type: Object,
    },
    generalItems: {
      required: true,
      type: Object,
    },
    summation: {
      required: true,
      type: Object,
    },
    SurchargeTypes: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    editMode() {},
  },
  data() {
    return {
      summ: {
        Id: "__NONE__",
        Name: " ",
        Cost: 0,
        ExternalCost: 0,
        FinalCost: 0,
        FinalExternalCost: 0,
        Tasks: {},
        CostCategories: {},
        MainCategories: {},
        Structures: {},
        Summations: {},
        Costs: {},
        Surcharge: {
          idSurcharge: -1,
          value: 0,
        },
      },
    };
  },
  methods: {
    ...mapActions("GeneralSummations", ["getGeneralSummation"]),
    AddCostCategory(costCategory, event) {
      if (event) {
        costCategory.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.CostCategories[costCategory.Id] = this.addCosts();
      } else {
        delete this.summ.CostCategories[costCategory.Id];
      }

      costCategory.SystemBlocked = event;
    },
    AddCostItem(costItem, event) {
      if (event) {
        costItem.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.Costs[costItem.Id] = this.addCosts();
      } else {
        delete this.summ.Costs[costItem.Id];
      }

      costItem.SystemBlocked = event;
    },
    AddTaskCategory(taskCategory, event) {
      if (event) {
        taskCategory.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.Structures[taskCategory.Id] = this.addCosts();
      } else {
        delete this.summ.Structures[taskCategory.Id];
      }

      taskCategory.SystemBlocked = event;
    },
    AddMainCategory(mainCategory, event) {
      if (event) {
        mainCategory.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.MainCategories[mainCategory.Id] = this.addCosts();
      } else {
        delete this.summ.MainCategories[mainCategory.Id];
      }

      mainCategory.SystemBlocked = event;
    },
    AddTask(task, event) {
      if (event) {
        task.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.Tasks[task.Id] = this.addCosts();
      } else {
        delete this.summ.Tasks[task.Id];
      }

      task.SystemBlocked = event;
    },
    AddSummation(summationInfo, event) {
      if (event) {
        summationInfo.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.Summations[summationInfo.Id] = this.addCosts();
      } else {
        delete this.summ.Summations[summationInfo.Id];
      }

      summationInfo.SystemBlocked = event;
    },
    addCosts() {
      return {
        Cost: 0,
        ExternalCost: 0,
      };
    },
    changedSummation(event) {
      // eslint-disable-next-line vue/no-mutating-props
      this.summ.Id = event.Id.toString();

      if (event.Id != this.summ.OriginalId)
        // eslint-disable-next-line vue/no-mutating-props
        this.summ.Name = event.Name;
      // eslint-disable-next-line vue/no-mutating-props
      else this.summ.Name = this.$t("general.name");
    },
    onShow() {
      this.summ = this.summation;
    },
    onHide() {
      this.summ = {
        Id: "__NONE__",
        Name: " ",
        Cost: 0,
        ExternalCost: 0,
        FinalCost: 0,
        FinalExternalCost: 0,
        Tasks: {},
        CostCategories: {},
        MainCategories: {},
        Structures: {},
        Summations: {},
        Costs: {},
        Surcharge: {
          idSurcharge: -1,
          value: 0,
        },
      };
    },
  },
  mounted() {
    this.getGeneralSummation({
      session: this.$session,
      api: this.$api,
      enabled: true,
    }).catch((e) => {
      console.log(e);
    });
  },
  computed: {
    ...mapGetters("MasterForm", ["summationEdit"]),
    ...mapGetters("GeneralSummations", ["generalSummations"]),
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.nameFromGeneralSummation"),
      };
    },
  },
};
</script>
