<template>
  <div>
    <b-card class="h-100" no-body>
      <b-card-header header-border-variant="light">
        <b-row>
          <b-col>
            <div v-if="!busy">
              <header-input
                v-if="editMode"
                type="text"
                :placeholder="$t('views.master-form.nameForm')"
                :label="$t('views.master-form.nameForm')"
                v-model="nameForm"
              />
              <h4 v-else>{{ nameForm }}</h4>
            </div>
            <div v-else>
              <b-skeleton width="80%" height="120%" class="mb-3"></b-skeleton>
            </div>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <back-button />
                <b-button
                  v-if="editMode && summationEdit"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.master-form.selectSummations')"
                  @click="EditSummationsHeader()"
                >
                  <b-icon-tools />
                </b-button>
                <b-button
                  v-if="editMode"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.viewMode')"
                  @click="SaveCase(false)"
                >
                  <view-icon />
                </b-button>
                <b-button
                  v-if="editMode"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.save')"
                  @click="SaveCase(true)"
                >
                  <font-awesome-icon id="disabled-wrapper" icon="save" />
                </b-button>
                <b-button
                  v-if="editable && !editMode"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.edit')"
                  @click="openEditMode"
                >
                  <b-icon-pencil />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="!busy">
              <div v-if="editMode">
                <b-form-select
                  v-model="allowPerPatientComponent"
                  :options="perPatientOptions"
                  style="max-width: 350px"
                ></b-form-select>
              </div>
              <span v-else>{{
                allowPerPatients === null
                  ? perPatientOptions[0].text
                  : allowPerPatients === true
                  ? perPatientOptions[1].text
                  : perPatientOptions[2].text
              }}</span>
            </div>
            <div v-else>
              <b-skeleton />
            </div>
          </b-col>
          <b-col cols="12" sm="6">
            <div v-if="!busy">
              <strong> {{ $t("views.master-form.parent") + ":" }}</strong>
              {{ parent ? parent : $t("views.master-form.none") }}
            </div>
            <div v-else>
              <b-skeleton />
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-tabs card fill>
        <b-tab :title="$t('views.home.MasterForm')">
          <b-card-text>
            <div v-if="busy">
              <div class="text-center text-danger my-2">
                <simple-loader />
              </div>
            </div>
            <b-card-text v-else>
              <ParametersEditionPanel v-if="editMode" />
              <ParametersViewPanel v-else />
              <GeneralTimeCategoriesPanel
                :editMode="editMode"
                :timeCategories="generalItems.TimeCategories"
                :modifications="items.GeneralModification.TimeCategories"
              />
              <MasterformTimeCategoriesPanel
                :editMode="editMode"
                :timeCategories="items.TimeCategories"
              />
              <GeneralMainCategoriesPanel
                :editMode="editMode"
                :resources="items.ResourcePool.Resources"
                :mainCategories="generalItems.MainCategories"
                :modifications="items.GeneralModification.MainCategories"
                @additem="addItem"
                @resourcechanged="onResourceChange"
                @hourschanged="onChangeHours"
                @showmessage="ShowMessage"
                @selectedItem="selectItemParameters"
              />
              <MasterformMainCategoriesPanel
                :editMode="editMode"
                :mainCategories="items.MainCategories"
                :resources="items.ResourcePool.Resources"
                @additem="addItem"
                @resourcechanged="onResourceChange"
                @hourschanged="onChangeHours"
                @showmessage="ShowMessage"
                @selectedItem="selectItemParameters"
              />
              <cost-section-edit
                v-if="editMode"
                :costCategories="items.CostCategories"
                :generalCostCategories="generalItems.CostCategories"
                :modifications="items.GeneralModification.CostCategories"
                @selectedItem="selectItemParameters"
                @additem="AddItem"
              />
              <cost-section-view
                v-else
                :costCategories="items.CostCategories"
                :generalCostCategories="generalItems.CostCategories"
              />
              <MasterformSummationsPanel
                :editMode="editMode"
                :items="items"
                :generalItems="generalItems"
                :modifications="items.GeneralModification.Summations"
              />
              <SummationsHeader
                :items="items"
                :generalItems="generalItems"
                :summationsHeader="summationsHeader"
              />
            </b-card-text>
          </b-card-text>
        </b-tab>
        <ResourcePool
          :resources="items.ResourcePool.Resources"
          :editMode="editMode"
          :duration="monthDurationCase"
          @updateAllResources="UpdateAllResources($event)"
        />
        <b-modal
          id="closeWithoutSaving"
          ref="exit-case-modal"
          @ok="ExitNotSaving"
          :title="$t('messages.titles.ExitNotSaved')"
        >
          <div class="d-block text-center">
            <h5>{{ $t("messages.prompts.ExitNotSaved") }}</h5>
          </div>
          <template v-slot:modal-footer>
            <slot name="footer">
              <b-button size="sm" @click="ExitNotSavingClose">
                {{ $t("buttons.Cancel") }}
              </b-button>
              <b-button size="sm" variant="success" @click="exitAndSaveCase">
                {{ $t("buttons.SaveAndExist") }}
              </b-button>
              <b-button size="sm" variant="danger" @click="ExitNotSaving">
                {{ $t("buttons.discard") }}
              </b-button>
            </slot>
          </template>
        </b-modal>

        <b-modal
          v-model="loadingViewMode"
          title="Loading view-mode"
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          hide-footer
        >
          <div class="d-block text-center">
            <h5>
              {{ $t("messages.masterFormViewMode") }}
            </h5>
          </div>
        </b-modal>
      </b-tabs>
    </b-card>
    <SelectParameters :item="selectedItem" @changed="changedParameter" />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import MasterformTimeCategoriesPanel from "@/components/MasterformTimeCategoriesPanel";
import MasterformMainCategoriesPanel from "@/components/MasterformMainCategoriesPanel";
import MasterformCostCategoriesPanel from "@/components/MasterformCostCategoriesPanel";
import MasterformSummationsPanel from "@/components/MasterformSummationsPanel";
import GeneralTimeCategoriesPanel from "@/components/GeneralTimeCategoriesPanel";
import GeneralMainCategoriesPanel from "@/components/GeneralMainCategoriesPanel";
import GeneralCostCategoriesPanel from "@/components/GeneralCostCategoriesPanel";
import SummationsHeader from "@/components/MasterCase/Header/SummationsHeader.vue";
import ParametersEditionPanel from "@/components/MasterForm/ParametersPanelEdition";
import ParametersViewPanel from "@/components/MasterForm/ParametersPanelView";
import headerinput from "@/components/header-input";
import ResourcePool from "@/components/ResourcePool";
import SelectParameters from "@/components/MasterForm/SelectParameter";
import ViewIcon from "@/components/general/icons/View.vue";
import costSectionView from "@/components/cost-calculation/partial-template/cost-section-view.vue";
import costSectionEdit from "../components/cost-calculation/partial-template/cost-section-edit.vue";

const components = {
  MasterformTimeCategoriesPanel,
  MasterformMainCategoriesPanel,
  MasterformCostCategoriesPanel,
  MasterformSummationsPanel,
  GeneralTimeCategoriesPanel,
  GeneralMainCategoriesPanel,
  GeneralCostCategoriesPanel,
  SummationsHeader,
  "header-input": headerinput,
  ResourcePool,
  ParametersEditionPanel,
  ParametersViewPanel,
  SelectParameters,
  "view-icon": ViewIcon,
  costSectionView,
  costSectionEdit,
};

const data = () => {
  return {
    nameForm: "",
    summationsHeader: {
      Summations: null,
    },
    busy: false,
    editMode: false,
    monthDurationCase: 0,
    selectedItem: {
      Parameters: [],
    },
    loadingViewMode: false,
    leftPage: false,
  };
};

const ConfirmLeave = function (to, from, next) {
  next();
  if (to.hash === "#edit") {
    // next();
    this.getCaseHeaderStatus({
      api: this.$api,
      session: this.$session,
      idOU: this.caseHeader.idOrganizationalUnit,
      idCase: this.caseHeader.idCase,
    })
      .then((response) => {
        let message;
        if (response.data.status === 1) {
          return;
        }
        if (response.data.status === 2)
          message =
            "Master form is being edited, do you still want to edit it?";
        this.$bvModal
          .msgBoxConfirm(message, {
            centered: true,
          })
          .then((answer) => {
            if (answer) {
              this.change = false;
              next();
            } else {
              next(false);
            }
          })
          .catch((err) => {
            console.log(err);
            next(false);
          });
      })
      .catch(() => next(false));
    return;
  }
  if (to.hash === "#display" && this.change) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to leave? you have unsaved changes!", {
        centered: true,
      })
      .then((answer) => {
        if (answer) {
          this.change = false;
          next();
        } else {
          next(false);
        }
      })
      .catch((err) => {
        console.log(err);
        next(false);
      });
    return;
  }
  next();
};

const computed = {
  ...mapGetters("MasterForm", [
    "SurchargeTypes",
    "items",
    "generalItems",
    "summaryFile",
    "idMasterForm",
    "nameMasterForm",
    "summationEdit",
    "allowPerPatients",
    "editable",
    "parent",
    "parameters",
  ]),
  allowPerPatientComponent: {
    get() {
      return this.allowPerPatients;
    },
    set(value) {
      this.setAllowPerPatients(value);
    },
  },
  perPatientOptions() {
    return [
      {
        value: null,
        text: this.$t("partialCostCalculation.perPatientOptions.0"),
      },
      {
        value: true,
        text: this.$t("partialCostCalculation.perPatientOptions.1"),
      },
      {
        value: false,
        text: this.$t("partialCostCalculation.perPatientOptions.2"),
      },
    ];
  },
};

const methods = {
  ...mapActions("MasterForm", [
    "getMasterForm",
    "UpdateTemplate",
    "getSurchargeTypes",
    "putParameters",
  ]),
  ...mapMutations("MasterForm", ["setAllowPerPatients"]),
  /**
   * @param event- resource (resourcepool) selected from resource task
   * @param resourceInfo, resource item that trigger the event
   * @param taskInfo Task item parent of Resource changed
   * @param structureInfo- category item parent of Resource changed
   * @param main category item of Resource changed
   */
  onResourceChange(event, resourceInfo, taskInfo, structureInfo, main) {
    resourceInfo.Resource = event;
    this.onChangeHours(
      resourceInfo.Hours,
      resourceInfo,
      taskInfo,
      structureInfo,
      main
    );
  },
  /**
   * @param hoursPerResource- resource (resourcepool) selected from resource task
   * @param resourceInfo, resource item that trigger the event
   * @param taskInfo Task item parent of Resource changed
   * @param structureInfo- category item parent of Resource changed
   * @param main category item of Resource changed
   */
  onChangeHours(
    hoursPerResource,
    resourceInfo,
    taskInfo,
    structureInfo,
    mainCategory
  ) {
    resourceInfo.Cost = resourceInfo.Resource.HourlyRate * hoursPerResource;
    resourceInfo.FinalCost = resourceInfo.Cost;
    this.recalculateTask(taskInfo, structureInfo, mainCategory);
  },
  /**
   * @param taskInfo Task item parent of Resource changed
   * @param structureInfo- category item parent of Resource changed
   * @param main category item of Resource changed
   */
  recalculateTask(taskInfo, structureInfo, mainCategory) {
    taskInfo.Hours = taskInfo.Resources.reduce((r, b) => r + b.Hours, 0);
    taskInfo.Cost = taskInfo.Resources.reduce((r, b) => r + b.FinalCost, 0);
    this.recalculateStructure(structureInfo, mainCategory);
  },
  /**
   * @param structureInfo- category item parent of Resource changed
   * @param main category item of Resource changed
   */
  recalculateStructure(structureInfo, mainCategory) {
    structureInfo.Cost = structureInfo.Tasks.reduce((r, b) => r + b.Cost, 0);
    this.recalculateMainCategory(mainCategory);
  },
  /**
   * @param main category item of Resource changed
   */
  recalculateMainCategory(mainCategory) {
    mainCategory.Cost = mainCategory.Structures.reduce((r, b) => r + b.Cost, 0);
  },
  addItem(array, newItem) {
    array.push(newItem);
  },
  removeEmptyResources() {
    for (let i = 0; i < this.items.ResourcePool.Resources.length; i++) {
      if (this.items.ResourcePool.Resources[i].Name === "")
        this.items.ResourcePool.Resources.splice(i, 1);
    }
  },
  async SaveCase(mode) {
    mode ? (this.loadingViewMode = false) : (this.loadingViewMode = true);
    this.UpdateStructures();
    this.removeEmptyResources();
    let summationHeader = Object.values(this.summationsHeader.Summations);
    //SummationHeader have the values selected in the summationHeader Popup
    //items.Summations have the values to be shown in the popup of summationHeader
    //if some summation was remove of items.Summations, this must be refect in SummationHeader array
    //for that reason the deleted item must be removed of summationHeader if this was selected.
    summationHeader = summationHeader.reduce((arraySum, item) => {
      let itemToBeAdded = this.items.Summations.find(
        (itemSummation) => itemSummation.Id == item.Id
      );
      if (itemToBeAdded) {
        item.Name = itemToBeAdded.Name;
        arraySum.push(item);
      }
      return arraySum;
    }, []);

    let summaryFile = JSON.stringify(summationHeader);

    try {
      const that = this;
      this.putParameters({
        api: this.$api,
        idMasterForm: this.idMasterForm,
        parameters: this.parameters,
      }).then(() => {
        this.UpdateTemplate({
          session: this.$session,
          api: this.$api,
          idMasterForm: this.idMasterForm,
          file: JSON.stringify(this.items),
          summaryFile: summaryFile,
          name: this.nameForm,
          allowPerPatients: this.allowPerPatientComponent,
        })
          .then(() => {
            if (!that.leftPage) {
              this.ShowMessage(
                this.$t("messages.titles.Success"),
                this.$t("messages.MasterFormUpdated")
              );
            }
            this.summationsHeader.Summations = this.summaryFile;
            this.nameForm = this.nameMasterForm;
            this.MergeBothTemplates();
            this.UpdateStructures();
          })
          .finally(() => {
            this.editMode = mode;

            if (!this.editMode) {
              this.cancelAutoSave();
            }

            this.loadingViewMode = false;
          });
      });
    } catch {
      this.ShowMessage(
        this.$t("messages.titles.Error"),
        this.$t("messages.MasterFormNotUpdated")
      );
    }
  },
  UpdateStructures() {
    for (var id in this.generalItems.MainCategories) {
      var mainCategory = this.generalItems.MainCategories[id];
      for (var index in mainCategory.Structures) {
        var strucutre = mainCategory.Structures[index];
        for (var item in strucutre.Tasks) {
          this.recalculateTask(strucutre.Tasks[item], strucutre, mainCategory);
        }
      }
    }
  },
  ShowMessage(title, message) {
    if (!this.leftPage) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    }
  },
  MergeRessourcePools() {
    for (var index in this.generalItems.ResourcePool.Resources) {
      var resource = this.generalItems.ResourcePool.Resources[index];
      var exists = this.items.ResourcePool.Resources.some(
        (r) => r.Id == resource.Id
      );
      if (!exists) this.items.ResourcePool.Resources.push(resource);
    }
  },
  MergeBothTemplates() {
    this.MergeTimeCategories();
    this.MergeMainCategories();
    this.MergeCostCategories();
    this.MergeSummations();
    this.MergeRessourcePools();
  },
  MergeTimeCategories() {
    for (var time in this.items.GeneralModification.TimeCategories) {
      var modification = this.items.GeneralModification.TimeCategories[time];
      var item = this.generalItems.TimeCategories.find(
        (x) => x.Id == modification.Id
      );
      if (item) {
        if (modification.Items) {
          for (var newItem in modification.Items.New) {
            item.Items.push(modification.Items.New[newItem]);
          }
          for (var modifiedItem in modification.Items.Modified) {
            var existing = item.Items.findIndex(
              (x) => x.Id === modification.Items.Modified[modifiedItem].Id
            );
            if (existing > -1)
              item.Items[existing] = modification.Items.Modified[modifiedItem];
          }
          for (var deletedItem in modification.Items.Deleted) {
            var itemIndex = item.Items.findIndex(
              (x) => x.Id === modification.Items.Deleted[deletedItem].Id
            );
            if (itemIndex > -1) {
              item.Items.splice(itemIndex, 1);
            }
          }
        }
        if (modification.Delete) {
          var index = this.generalItems.TimeCategories.indexOf(item);
          if (index > -1) this.generalItems.TimeCategories.splice(index, 1);
        }
      }
    }
  },
  MergeCostCategories() {
    for (var cost in this.items.GeneralModification.CostCategories) {
      var modification = this.items.GeneralModification.CostCategories[cost];
      var item = this.generalItems.CostCategories.find(
        (x) => x.Id == modification.Id
      );
      if (item) {
        item.Name = modification.Name;
        item.Blocked = modification.Blocked;
        if (modification.Items) {
          for (var newItem in modification.Items.New) {
            item.Items.push(modification.Items.New[newItem]);
          }
          for (var modifiedItem in modification.Items.Modified) {
            var existing = item.Items.findIndex(
              (x) => x.Id === modification.Items.Modified[modifiedItem].Id
            );
            if (existing > -1)
              item.Items[existing] = modification.Items.Modified[modifiedItem];
          }
          for (var deletedItem in modification.Items.Deleted) {
            var itemIndex = item.Items.findIndex(
              (x) => x.Id === modification.Items.Deleted[deletedItem].Id
            );
            if (itemIndex > -1) {
              item.Items.splice(itemIndex, 1);
            }
          }
        }
        if (modification.Delete) {
          var index = this.generalItems.CostCategories.indexOf(item);
          if (index > -1) this.generalItems.CostCategories.splice(index, 1);
        }
      }
    }
  },
  MergeMainCategories() {
    for (var main in this.items.GeneralModification.MainCategories) {
      var category = this.items.GeneralModification.MainCategories[main];
      var item = this.generalItems.MainCategories.find(
        (x) => x.Id == category.Id
      );
      if (item) {
        if (category.Name) {
          item.Name = category.Name;
        }
        item.Blocked = category.Blocked;
        if (category.Structures) {
          for (var newItem in category.Structures.New) {
            item.Structures.push(category.Structures.New[newItem]);
          }
          for (var modifiedItem in category.Structures.Modified) {
            var existing = item.Structures.findIndex(
              (x) => x.Id === category.Structures.Modified[modifiedItem].Id
            );
            if (existing > -1) {
              item.Structures[existing].Name =
                category.Structures.Modified[modifiedItem].Name;
              item.Structures[existing].Blocked =
                category.Structures.Modified[modifiedItem].Blocked;
              this.MergeTasks(
                category.Structures.Modified[modifiedItem],
                item.Structures[existing]
              );
            }
          }
          for (var deletedItem in category.Structures.Deleted) {
            var itemIndex = item.Structures.findIndex(
              (x) => x.Id === category.Structures.Deleted[deletedItem].Id
            );
            if (itemIndex > -1) {
              item.Structures.splice(itemIndex, 1);
            }
          }
        }
        if (category.Delete) {
          var index = this.generalItems.MainCategories.indexOf(item);
          if (index > -1) this.generalItems.MainCategories.splice(index, 1);
        }
      }
    }
  },
  MergeTasks(structure, real) {
    for (var index in structure.Tasks.New) {
      var exists = real.Tasks.findIndex(
        (x) => x.Id === structure.Tasks.New[index].Id
      );
      if (exists < 0) real.Tasks.push(structure.Tasks.New[index]);
    }
    for (var modifiedItem in structure.Tasks.Modified) {
      var itemIndex = real.Tasks.findIndex(
        (x) => x.Id === structure.Tasks.Modified[modifiedItem].Id
      );
      if (itemIndex > -1) {
        real.Tasks[itemIndex].Description =
          structure.Tasks.Modified[modifiedItem].Description;
        real.Tasks[itemIndex].Blocked =
          structure.Tasks.Modified[modifiedItem].Blocked;
        real.Tasks[itemIndex].SystemBlocked =
          structure.Tasks.Modified[modifiedItem].SystemBlocked;
        real.Tasks[itemIndex].Cost =
          structure.Tasks.Modified[modifiedItem].Cost;
        real.Tasks[itemIndex].ExternalCost =
          structure.Tasks.Modified[modifiedItem].ExternalCost;
        real.Tasks[itemIndex].Hours =
          structure.Tasks.Modified[modifiedItem].Hours;
        real.Tasks[itemIndex].Comment =
          structure.Tasks.Modified[modifiedItem].Comment;
        real.Tasks[itemIndex].Parameters =
          structure.Tasks.Modified[modifiedItem].Parameters || [];

        if (Array.isArray(structure.Tasks.Modified[modifiedItem].Resources)) {
          real.Tasks[itemIndex].Resources =
            structure.Tasks.Modified[modifiedItem].Resources;
        } else {
          this.MergeResources(
            structure.Tasks.Modified[modifiedItem].Resources,
            real.Tasks[itemIndex].Resources
          );
        }
      }
    }
    for (var deletedItem in structure.Tasks.Deleted) {
      var deletionIndex = real.Tasks.findIndex(
        (x) => x.Id === structure.Tasks.Deleted[deletedItem].Id
      );
      if (deletionIndex > -1) {
        real.Tasks.splice(deletionIndex, 1);
      }
    }
  },
  MergeResources(resources, real) {
    for (var index in resources.New) {
      var exists = real.findIndex((x) => x.Id === resources.New[index].Id);
      if (exists < 0) real.push(resources.New[index]);
    }
    for (var modifiedItem in resources.Modified) {
      var itemIndex = real.findIndex(
        (x) => x.Id === resources.Modified[modifiedItem].Id
      );
      if (itemIndex > -1) real[itemIndex] = resources.Modified[modifiedItem];
    }
    for (var deletedItem in resources.Deleted) {
      var deletionIndex = real.findIndex(
        (x) => x.Id === resources.Deleted[deletedItem].Id
      );
      if (deletionIndex > -1) {
        real.splice(deletionIndex, 1);
      }
    }
  },
  MergeSummations() {
    for (var index in this.items.GeneralModification.Summations) {
      var summation = this.items.GeneralModification.Summations[index];
      var realIndex = this.generalItems.Summations.findIndex(
        (x) => x.Id === summation.Id
      );
      if (realIndex > -1) {
        if (summation.Delete) {
          this.generalItems.Summations.splice(realIndex, 1);
        } else {
          this.generalItems.Summations[realIndex] = summation;
        }
      }
    }
  },
  AddItem(array, newItem) {
    if (!array.some((x) => x.Id.toString() == newItem.Id.toString())) {
      array.push(newItem);
    } else {
      this.ShowMessage(
        this.$t("views.master-form.messages.materialCostAlreadyExists.title"),
        this.$t("views.master-form.messages.materialCostAlreadyExists.text")
      );
    }
  },
  EditSummationsHeader() {
    this.$bvModal.show("summations-header-modal");
  },
  UpdateAllResources(ResourceInfo) {
    for (var mainCategory in this.items.MainCategories) {
      for (var structure in this.items.MainCategories[mainCategory]
        .Structures) {
        for (var task in this.items.MainCategories[mainCategory].Structures[
          structure
        ].Tasks) {
          for (var resource in this.items.MainCategories[mainCategory]
            .Structures[structure].Tasks[task].Resources) {
            if (
              this.items.MainCategories[mainCategory].Structures[structure]
                .Tasks[task].Resources[resource].Resource.Id === ResourceInfo.Id
            ) {
              this.items.MainCategories[mainCategory].Structures[
                structure
              ].Tasks[task].Resources[resource].Resource = ResourceInfo;
              this.onChangeHours(
                this.items.MainCategories[mainCategory].Structures[structure]
                  .Tasks[task].Resources[resource].Hours,
                this.items.MainCategories[mainCategory].Structures[structure]
                  .Tasks[task].Resources[resource],
                this.items.MainCategories[mainCategory].Structures[structure]
                  .Tasks[task],
                this.items.MainCategories[mainCategory].Structures[structure],
                this.items.MainCategories[mainCategory]
              );
            }
          }
        }
      }
    }
  },
  ExitNotSavingClose() {
    this.$bvModal.hide("closeWithoutSaving");
  },
  async ExitNotSaving() {
    //this.$api.PutSubcaseState(this.$route.params.idSubcase, 1);
    this.leftPage = true;
    this.cancelAutoSave();
    this.next();
  },
  async exitAndSaveCase() {
    try {
      await this.SaveCase(true);
      this.leftPage = true;
      this.cancelAutoSave();
      this.next();
    } catch (error) {
      console.log(error);
    }
  },
  selectItemParameters(item) {
    this.selectedItem = item;
    this.selectedItem.Parameters = this.selectedItem.Parameters || [];
    this.$bvModal.show("select-parameter-modal");
  },
  changedParameter(selected, item) {
    if (selected) this.selectedItem.Parameters.push(item);
    else
      this.selectedItem.Parameters = this.selectedItem.Parameters.filter(
        (x) => x.idParameter != item.idParameter
      );
  },
  autoSave() {
    this.SaveCase(true);
  },
  cancelAutoSave() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  },
  openEditMode() {
    this.editMode = true;
    this.timer = setInterval(this.autoSave, 300000);
  },
};

const lifeCycle = {
  created() {
    const { idMasterForm } = this.$route.params;
    if (!idMasterForm) return;
    this.busy = true;
    this.getMasterForm({
      api: this.$api,
      session: this.$session,
      idMasterForm: idMasterForm,
    })
      .then(() => {
        this.summationsHeader.Summations = this.summaryFile;
        this.nameForm = this.nameMasterForm;
        this.MergeBothTemplates();
        this.UpdateStructures();
      })
      .finally(() => {
        this.busy = false;
      });
    this.leftPage = false;
    this.getSurchargeTypes({ session: this.$session, api: this.$api });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.load && this.editMode) {
      this.next = next;
      this.$refs["exit-case-modal"].show();
      next(false);
    } else {
      this.leftPage = true;
      this.cancelAutoSave();
      next();
    }
  },
};

export default {
  components,
  data,
  beforeRouteUpdate: ConfirmLeave,
  beforeRouteLeave: ConfirmLeave,
  computed,
  methods,
  ...lifeCycle,
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
