<template>
  <b-modal
    ref="add-parameter"
    id="add-parameter-modal"
    :title="$t('views.caseTemplateEdition.newParameter')"
    @show="resetModal"
  >
    <b-form-group
      :label="$t('views.caseTemplateEdition.parameter')"
      label-for="select-parameter"
      :invalid-feedback="$t('views.caseTemplateEdition.parameterRequired')"
      label-cols="3"
      :state="parameterState"
      inline
    >
      <b-input-group>
        <b-form-select
          v-model="form.idParameter"
          required
          :state="parameterState"
          :disabled="busy"
        >
          <template #first>
            <b-form-select-option :value="null" disabled style="opacity: 0.5">{{
              $t("views.caseTemplateEdition.parameterInstructions")
            }}</b-form-select-option>
          </template>
          <b-form-select-option
            id="select-parameter"
            v-for="(parameter, index) in parameters.filter(
              (x) => !existing.some((y) => y.idParameter == x.idParameter)
            )"
            v-bind:key="index"
            :value="parameter"
            >{{ parameter.parameterName }}</b-form-select-option
          >
        </b-form-select>
        <template #append>
          <b-button
            @click="loadParameters"
            size="sm"
            variant="outline-info"
            :disables="busy"
          >
            <b-icon-arrow-clockwise />
          </b-button>
        </template>
      </b-input-group>
    </b-form-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button variant="primary" @click="addParameter" :disabled="busy">
          {{ $t("views.caseTemplateEdition.addParameter") }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const initForm = {
  idParameter: null,
};

export default {
  props: {
    idOrganizationalUnit: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      form: { ...initForm },
      busy: false,
      parameterState: null,
    };
  },
  computed: {
    ...mapGetters("Parameters", ["parameters"]),
    ...mapGetters("MasterForm", { existing: "parameters" }),
  },
  methods: {
    ...mapActions("MasterForm", {
      postParameter: "postParameter",
    }),
    ...mapActions("Parameters", {
      getParameters: "getParameters",
    }),
    checkFormValidity() {
      let check = (this.parameterState = true);
      if (!this.form.idParameter) this.parameterState = check = false;
      return check;
    },
    resetModal() {
      this.form = { ...initForm };
      this.parameterState = null;
      this.loadParameters();
    },
    addParameter(oEvent) {
      if (!this.checkFormValidity()) {
        oEvent.preventDefault();
        return;
      }

      this.postParameter({
        idParameter: this.form.idParameter,
      });

      this.$refs["add-parameter"].hide();
    },
    loadParameters() {
      this.busy = true;
      this.getParameters({
        api: this.$api,
        idOrganizationalUnit: this.idOrganizationalUnit,
        enabled: true,
      }).finally(() => {
        this.busy = false;
      });
    },
  },
};
</script>
