<template>
  <div>
    <Panel
      v-if="items.Summations"
      :id="'Summations'"
      :title="{ Name: $t('fields.Summations.Summations') }"
      :editable="false"
      :general="false"
    >
      <b-table
        :fields="fields.Summations"
        :items="[...generalItems.Summations, ...items.Summations]"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(Cost)="row">
          <b-button
            size="sm"
            variant="primary"
            class="mx-auto"
            style="display: block"
            @click="EditSummation(row.item)"
          >
            <div v-if="editMode && summationEdit">
              <b-icon-pencil />
            </div>
            <div v-else>
              <view-icon />
            </div>
          </b-button>
        </template>

        <template v-slot:cell(Surcharge)="row">
          {{ row.item.Surcharge.surchargeName }}
        </template>

        <template v-slot:cell(OnlyPercentage)="row">
          <b-row>
            <b-col align-self="center">
              <b-checkbox :checked="row.item.OnlyPercentage" disabled />
            </b-col>
          </b-row>
        </template>

        <template v-slot:cell(PerPatient)="row">
          <div class="mx-auto">
            <b-checkbox :checked="row.item.PerPatient" disabled />
          </div>
        </template>

        <template v-slot:cell(OnlyInternal)="row">
          <div class="mx-auto">
            <b-checkbox :checked="row.value" disabled />
          </div>
        </template>

        <template v-slot:cell(FinalCost)="row">
          <b-button
            v-if="editMode && summationEdit"
            size="sm"
            variant="danger"
            class="mx-auto"
            style="display: block"
            @click="DeleteSummation(row.item, items.Summations)"
            ><b-icon-dash-square
          /></b-button>
        </template>
      </b-table>
      <b-button
        v-if="editMode && summationEdit"
        size="sm"
        @click="AddSummation(items.Summations)"
        variant="success"
      >
        <b-icon-plus-circle /> {{ $t("views.master-form.addSummation") }}
      </b-button>
    </Panel>
    <SelectSummationFields
      :items="items"
      :generalItems="generalItems"
      :summation="currentSummation"
      :SurchargeTypes="SurchargeTypes"
      :editMode="editMode"
    />
  </div>
</template>
<script>
import Panel from "@/components/panel";
import SelectSummationFields from "@/components/SelectSummationFields";
import { mapGetters } from "vuex";

import ViewIcon from "@/components/general/icons/View.vue";

export default {
  props: {
    items: {
      required: true,
      type: Object,
    },
    generalItems: {
      required: true,
      type: Object,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    modifications: {
      required: true,
      type: Array,
    },
  },
  watch: {
    editMode() {},
  },
  components: {
    Panel,
    SelectSummationFields,
    "view-icon": ViewIcon,
  },
  data() {
    return {
      currentSummation: {
        Id: "-1",
        Name: " ",
        Cost: 0,
        FinalCost: 0,
        Tasks: {},
        CostCategories: {},
        MainCategories: {},
        Structures: {},
        Summations: {},
        Costs: {},
      },
    };
  },
  methods: {
    AddSummation(summations) {
      let newSummation = {
        Id: "Summation_" + String(Date.now()),
        Name: "",
        Cost: 0,
        ExternalCost: 0,
        FinalCost: 0,
        FinalExternalCost: 0,
        Tasks: {},
        CostCategories: {},
        MainCategories: {},
        Structures: {},
        Summations: {},
        Costs: {},
        Surcharge: {
          idSurcharge: 0,
          surchargeName: "None (0%)",
          value: 0,
        },
      };

      newSummation.OriginalId = newSummation.Id;

      summations.push(newSummation);
      this.EditSummation(newSummation);
    },
    EditSummation(summation) {
      this.currentSummation = summation;
      var summItem = this.generalItems.Summations.find(
        (x) => x.Id === summation.Id
      );
      if (summItem) {
        var index = this.modifications.findIndex((x) => x.Id === summItem.Id);
        if (index > -1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications[index] = summItem;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications.push(summItem);
        }
      }
      this.$bvModal.show("select-fields-modal");
    },
    DeleteSummation(item, summations) {
      const index = summations.indexOf(item);
      if (index > -1) {
        summations.splice(index, 1);
      } else {
        var summItem = this.generalItems.Summations.findIndex(
          (x) => x.Id === item.Id
        );
        if (summItem > -1) {
          item.Delete = true;
          var indSum = this.modifications.findIndex((x) => x.Id === item.Id);
          if (indSum > -1) {
            // eslint-disable-next-line vue/no-mutating-props
            this.modifications[indSum] = item;
          } else {
            // eslint-disable-next-line vue/no-mutating-props
            this.modifications.push(item);
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.generalItems.Summations.splice(summItem, 1);
        }
      }
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
  },
  computed: {
    ...mapGetters("MasterForm", ["SurchargeTypes", "summationEdit"]),
    fields() {
      return {
        Summations: [
          {
            key: "Name",
            sortable: true,
            label: this.$t("fields.Summations.Name"),
          },
          {
            key: "Surcharge",
            sortable: false,
            label: this.$t("fields.Summations.Value"),
          },
          {
            key: "PerPatient",
            sortable: false,
            label: this.$t("fields.Summations.PerPatient"),
          },
          {
            key: "OnlyPercentage",
            sortable: false,
            label: this.$t("fields.Summations.OnlyPercentage"),
          },
          {
            key: "OnlyInternal",
            sortable: false,
            label: this.$t("fields.Summations.OnlyInternal"),
          },
          {
            key: "Cost",
            sortable: false,
            label: " ",
          },
          {
            key: "FinalCost",
            sortable: false,
            label: " ",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
