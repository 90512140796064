<template>
  <div>
    <Panel
      v-for="(time, index) in timeCategories"
      v-bind:key="time.Id"
      :id="'time' + index"
      :title="time"
      :editable="editMode"
      :general="false"
      @blocked="time.Blocked = $event"
      @renamed="time.Name = $event"
    >
      <b-table
        :fields="fields.TimeCategories"
        :items="time.Items"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-if="editMode" v-slot:cell(Name)="row">
          <b-row align-v="center">
            <b-col>
              <b-form-input
                :disabled="row.item.Blocked"
                v-model="row.item.Name"
              />
            </b-col>
            <b-col cols="2">
              <b-button
                :pressed.sync="row.item.Blocked"
                variant="outline-primary"
                size="sm"
                ><b-icon scale="1.4" icon="lock-fill" v-if="row.item.Blocked" />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
              <b-button
                v-if="!row.item.Blocked"
                size="sm"
                @click="DeleteTimeItem(row.item, time)"
                variant="danger"
                class="rightBlock"
              >
                <b-icon-dash-square />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-button
        v-if="editMode"
        size="sm"
        @click="AddTimeItem(time.Items)"
        variant="primary"
      >
        <b-icon-plus-circle /> {{ $t("views.master-form.addItem") }}
      </b-button>
      <b-button
        v-if="editMode && !time.Blocked"
        size="sm"
        @click="DeleteCategory(time, timeCategories)"
        variant="danger"
        style="float: right"
      >
        <b-icon-dash-square /> {{ $t("buttons.DeleteTimeCategory") }}
      </b-button>
      <hr />
    </Panel>
    <b-button
      v-if="editMode && summationEdit"
      size="sm"
      @click="AddTimeCategory()"
      variant="success"
    >
      <b-icon-plus-circle /> {{ $t("buttons.AddTimeCategory") }}
    </b-button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Panel from "@/components/panel";
export default {
  props: {
    timeCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    Panel,
  },
  watch: {
    editMode() {},
  },
  methods: {
    DeleteCategory(category, categoriesInfo) {
      const index = categoriesInfo.indexOf(category);
      if (index > -1) {
        categoriesInfo.splice(index, 1);
      }
    },
    AddTimeItem(timeItems) {
      let newTimeItem = {
        Id: "TimeItem_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Name: "",
        Months: "",
      };
      timeItems.push(newTimeItem);
    },
    DeleteTimeItem(time, items) {
      const index = items.Items.indexOf(time);
      if (index > -1) {
        items.Items.splice(index, 1);
      }
    },
    AddTimeCategory() {
      let newCostItem = {
        Blocked: false,
        SystemBlocked: false,
        Id: "TimeCategory_" + String(Date.now()),
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        Items: [],
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.timeCategories.push(newCostItem);
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
  },
  computed: {
    ...mapGetters("MasterForm", ["summationEdit"]),
    fields() {
      return {
        TimeCategories: [
          {
            key: "Name",
            sortable: false,
            label: this.$t("fields.TimeCategories.Name"),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
