<template>
  <div>
    <Panel
      v-for="(time, index) in timeCategories"
      v-bind:key="time.Id"
      :id="'general_time' + index"
      :title="time"
      :editable="editMode"
      :general="true"
      @blocked="time.Blocked = $event"
      @renamed="time.Name = $event"
    >
      <b-table
        :fields="fields.TimeCategories"
        :items="time.Items"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-if="editMode" v-slot:cell(Name)="row">
          <b-row align-v="center">
            <b-col>
              <b-form-input
                :disabled="row.item.Blocked"
                v-model="row.item.Name"
                @change="ModifyTimeItem(row.item, time.Id)"
              />
            </b-col>
            <b-col cols="2">
              <b-icon
                scale="1.5"
                v-b-popover.hover.top="popupGeneralBlocked"
                icon="shield-lock-fill"
                v-if="row.item.GeneralBlocked"
              />
              <b-button
                v-else
                :pressed.sync="row.item.Blocked"
                @click="$emit('blocked', blocked)"
                variant="outline-primary"
                size="sm"
                ><b-icon scale="1.4" icon="lock-fill" v-if="row.item.Blocked" />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
              <b-button
                v-if="!row.item.Blocked"
                size="sm"
                class="rightBlock"
                @click="DeleteTimeItem(row.item, time)"
                variant="danger"
              >
                <b-icon-dash-square />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-button
        v-if="editMode"
        size="sm"
        @click="AddTimeItem(time)"
        variant="primary"
      >
        <b-icon-plus-circle /> {{ $t("views.master-form.addItem") }}
      </b-button>
      <b-button
        v-if="editMode && !time.Blocked"
        size="sm"
        @click="DeleteCategory(time, timeCategories)"
        variant="danger"
        style="float: right"
      >
        <b-icon-dash-square /> {{ $t("buttons.DeleteTimeCategory") }}
      </b-button>
      <hr />
    </Panel>
  </div>
</template>
<script>
import Panel from "@/components/panel";
export default {
  props: {
    timeCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    modifications: {
      required: true,
      type: Array,
    },
  },
  components: {
    Panel,
  },
  watch: {
    editMode() {},
  },
  methods: {
    AddTimeItem(timeItems) {
      let newTimeItem = {
        Id: "TimeItem_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Name: "",
        Months: "",
      };

      var item = this.modifications.find((x) => x.Id === timeItems.Id);
      if (!item) {
        item = {
          Id: timeItems.Id,
          Items: {
            New: [newTimeItem],
            Modified: [],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
        item.Items.New.push(newTimeItem);
      }
      // eslint-disable-next-line vue/no-mutating-props
      timeItems.Items.push(newTimeItem);
    },
    ModifyTimeItem(timeItem, id) {
      var item = this.modifications.find((x) => x.Id === id);
      if (!item) {
        item = {
          Id: id,
          Items: {
            New: [],
            Modified: [timeItem],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
        var index = item.Items.Modified.findIndex((x) => x.Id === timeItem.Id);
        if (index > -1) item.Items.Modified[index] = timeItem;
        else item.Items.Modified.push(timeItem);
      }
    },
    DeleteTimeItem(time, items) {
      const index = items.Items.indexOf(time);
      if (index > -1) {
        var item = this.modifications.find((x) => x.Id === items.Id);
        if (!item) {
          item = {
            Id: items.Id,
            Items: {
              New: [],
              Modified: [],
              Deleted: [
                {
                  Id: time.Id,
                },
              ],
            },
          };
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications.push(item);
        } else {
          item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
          item.Items.Deleted.push({
            Id: time.Id,
          });
        }

        items.Items.splice(index, 1);
      }
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
    DeleteCategory(category, categoriesInfo) {
      const index = categoriesInfo.indexOf(category);
      if (index > -1) {
        var item = this.modifications.find((x) => x.Id == category.Id);

        if (!item) {
          item = {
            Id: category.Id,
            Delete: true,
          };
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications.push(item);
        } else {
          item.Delete = true;
        }

        categoriesInfo.splice(index, 1);
      }
    },
  },
  computed: {
    fields() {
      return {
        TimeCategories: [
          {
            key: "Name",
            sortable: false,
            label: this.$t("fields.TimeCategories.Name"),
          },
        ],
      };
    },
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.blockedInParent"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
