<template>
  <div>
    <Panel
      v-for="(cost, index) in costCategories"
      v-bind:key="cost.Id"
      :id="'cost' + index"
      :title="cost"
      :editable="editMode"
      :general="false"
      @blocked="cost.Blocked = $event"
      @renamed="cost.Name = $event"
    >
      <b-table
        :fields="fields.CostCategories"
        :items="cost.Items"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-if="editMode" v-slot:cell(Name)="row">
          <b-row align-v="center">
            <b-col>
              <b-form-input
                v-model="row.item.Name"
                :disabled="row.item.Blocked || row.item.IsMaterialCost"
              />
            </b-col>
            <b-col cols="3">
              <b-button
                :pressed.sync="row.item.Blocked"
                variant="outline-primary"
                size="sm"
                ><b-icon scale="1.4" icon="lock-fill" v-if="row.item.Blocked" />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
              <b-button
                v-if="!row.item.Blocked"
                class="mx-1 rightBlock"
                size="sm"
                @click="DeleteCostItem(row.item, cost)"
                variant="danger"
              >
                <b-icon-dash-square />
              </b-button>
              <b-button
                class="mx-1 rightBlock"
                size="sm"
                @click="SelectParametersItem(row.item)"
                variant="primary"
              >
                <b-icon-sliders />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-button
            v-if="editMode"
            size="sm"
            @click="AddCostItem(cost.Items)"
            variant="primary"
          >
            <b-icon-plus-circle /> {{ $t("views.master-form.addItem") }}
          </b-button>
        </b-col>
        <b-col>
          <material-cost-item-select-option
            v-if="editMode"
            :costId="cost.Id"
            @onItemSelected="AddMaterialCostItem(cost.Items, $event)"
          />
        </b-col>
        <b-col>
          <b-button
            v-if="editMode && !cost.Blocked"
            size="sm"
            @click="DeleteCategory(index, costCategories)"
            variant="danger"
            style="float: right"
          >
            <b-icon-dash-square />
            {{ $t("views.master-form.deleteCostCategory") }}
          </b-button>
        </b-col>
      </b-row>
      <hr />
    </Panel>
    <b-button
      v-if="editMode && summationEdit"
      size="sm"
      @click="AddCostCategory()"
      variant="success"
    >
      <b-icon-plus-circle /> {{ $t("views.master-form.addCostCategory") }}
    </b-button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Panel from "@/components/panel";
import MaterialCostItemSelectOption from "@/components/cost-calculation/material-cost-item-select-option.vue";

export default {
  components: {
    Panel,
    MaterialCostItemSelectOption,
  },
  data() {
    return {
      selectedItem: {
        Parameters: [],
      },
    };
  },
  props: {
    costCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    editMode() {},
  },
  methods: {
    DeleteCategory(index, categoriesInfo) {
      if (index <= -1) return;
      var array_move = function (arr, old_index, new_index) {
        if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
      };

      array_move(categoriesInfo, index, categoriesInfo.length - 1);

      categoriesInfo.splice(categoriesInfo.length - 1, 1);
    },
    AddCostItem(costItems) {
      let newCostItem = {
        Id: "CostItem_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        FinalCost: 0,
        FinalExternalCost: 0,
        Parameters: [],
      };
      this.$emit("additem", costItems, newCostItem);
    },
    AddMaterialCostItem(costItems, materialCostItemData) {
      let newCostItem = {
        Id:
          materialCostItemData.idMaterialCostCategory +
          "." +
          materialCostItemData.idMaterialCostClass,
        Blocked: false,
        SystemBlocked: false,
        Cost: materialCostItemData.average,
        ExternalCost: materialCostItemData.average,
        Name: materialCostItemData.name,
        FinalCost: materialCostItemData.average,
        FinalExternalCost: materialCostItemData.average,
        Parameters: [],
        IsMaterialCost: true,
        IdMaterialCostCategory: materialCostItemData.idMaterialCostCategory,
        IdMaterialCostClass: materialCostItemData.idMaterialCostClass,
      };
      this.$emit("additem", costItems, newCostItem);
    },
    DeleteCostItem(cost, items) {
      const index = items.Items.indexOf(cost);
      if (index > -1) {
        items.Items.splice(index, 1);
      }
    },
    AddCostCategory() {
      let newCostItem = {
        Blocked: false,
        SystemBlocked: false,
        Id: "CostCategory_" + String(Date.now()),
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        Items: [],
      };
      this.$emit("additem", this.costCategories, newCostItem);
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
    SelectParametersItem(item) {
      this.$emit("selectedItem", item);
    },
  },
  computed: {
    ...mapGetters("MasterForm", ["summationEdit"]),
    fields() {
      return {
        CostCategories: [
          {
            key: "Name",
            sortable: false,
            label: this.$t("fields.CostCategories.Item"),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
