<template>
  <div>
    <Panel
      v-for="(cost, index) in costCategories"
      v-bind:key="index.Id"
      :id="'general_cost' + index"
      :title="cost"
      :editable="editMode"
      :general="true"
      @blocked="blockChanged(cost, $event)"
      @renamed="nameChanged(cost, $event)"
    >
      <b-table
        :fields="fields.CostCategories"
        :items="cost.Items"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-if="editMode" v-slot:cell(Name)="row">
          <b-row align-v="center">
            <b-col>
              <b-form-input
                v-model="row.item.Name"
                :disabled="row.item.Blocked || row.item.IsMaterialCost"
                @change="ModifyCostItem(row.item, cost.Id)"
              />
            </b-col>
            <b-col cols="3">
              <b-icon
                scale="1.5"
                v-b-popover.hover.top="popupGeneralBlocked"
                icon="shield-lock-fill"
                v-if="row.item.GeneralBlocked"
              />
              <b-button
                v-else
                :pressed.sync="row.item.Blocked"
                @click="ModifyCostItem(row.item, cost.Id)"
                variant="outline-primary"
                size="sm"
                ><b-icon scale="1.4" icon="lock-fill" v-if="row.item.Blocked" />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
              <b-button
                v-if="!row.item.Blocked"
                class="ml-2 rightBlock"
                size="sm"
                @click="DeleteCostItem(row.item, cost)"
                variant="danger"
              >
                <b-icon-dash-square />
              </b-button>
              <b-button
                class="ml-2 rightBlock"
                size="sm"
                @click="SelectParametersItem(row.item)"
                variant="primary"
              >
                <b-icon-sliders />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-button
            v-if="editMode"
            size="sm"
            @click="AddCostItem(cost)"
            variant="primary"
          >
            <b-icon-plus-circle /> {{ $t("views.master-form.addItem") }}
          </b-button>
        </b-col>
        <b-col>
          <material-cost-item-select-option
            v-if="editMode"
            :costId="cost.Id"
            @onItemSelected="AddMaterialCostItem(cost, $event)"
          />
        </b-col>
        <b-col>
          <b-button
            v-if="editMode && !cost.Blocked"
            size="sm"
            @click="DeleteCategory(cost, costCategories)"
            variant="danger"
            style="float: right"
          >
            <b-icon-dash-square />
            {{ $t("views.master-form.deleteCostCategory") }}
          </b-button>
        </b-col>
      </b-row>
      <hr />
    </Panel>
  </div>
</template>
<script>
import Panel from "@/components/panel";
import MaterialCostItemSelectOption from "@/components/cost-calculation/material-cost-item-select-option.vue";

export default {
  components: {
    Panel,
    MaterialCostItemSelectOption,
  },
  props: {
    costCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    modifications: {
      required: true,
      type: Array,
    },
  },
  watch: {
    editMode() {},
  },
  methods: {
    AddMaterialCostItem(costItems, materialCostItemData) {
      let newCostItem = {
        Id:
          materialCostItemData.idMaterialCostCategory +
          "." +
          materialCostItemData.idMaterialCostClass,
        Blocked: false,
        SystemBlocked: false,
        Cost: materialCostItemData.average,
        ExternalCost: materialCostItemData.average,
        Name: materialCostItemData.name,
        Parameters: [],
        IsMaterialCost: true,
        IdMaterialCostCategory: materialCostItemData.idMaterialCostCategory,
        IdMaterialCostClass: materialCostItemData.idMaterialCostClass,
      };

      var success = true;

      var item = this.modifications.find((x) => x.Id === costItems.Id);
      if (!item) {
        item = {
          Id: costItems.Id,
          Items: {
            New: [newCostItem],
            Modified: [],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
        if (
          !item.Items.New.some(
            (x) => x.Id.toString() === newCostItem.Id.toString()
          )
        ) {
          item.Items.New.push(newCostItem);
        } else {
          success = false;
          this.ShowMessage(
            this.$t(
              "views.master-form.messages.materialCostAlreadyExists.title"
            ),
            this.$t("views.master-form.messages.materialCostAlreadyExists.text")
          );
        }
      }
      if (success) {
        // eslint-disable-next-line vue/no-mutating-props
        costItems.Items.push(newCostItem);
      }
    },
    AddCostItem(costItems) {
      let newCostItem = {
        Id: "CostItem_" + String(Date.now()),
        Blocked: false,
        SystemBlocked: false,
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        Parameters: [],
      };

      var item = this.modifications.find((x) => x.Id === costItems.Id);
      if (!item) {
        item = {
          Id: costItems.Id,
          Items: {
            New: [newCostItem],
            Modified: [],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
        item.Items.New.push(newCostItem);
      }
      // eslint-disable-next-line vue/no-mutating-props
      costItems.Items.push(newCostItem);
    },
    DeleteCostItem(cost, items) {
      const index = items.Items.indexOf(cost);
      if (index > -1) {
        var item = this.modifications.find((x) => x.Id === items.Id);
        if (!item) {
          item = {
            Id: items.Id,
            Items: {
              New: [],
              Modified: [],
              Deleted: [
                {
                  Id: cost.Id,
                },
              ],
            },
          };
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications.push(item);
        } else {
          item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
          item.Items.Deleted.push({
            Id: cost.Id,
          });
        }

        items.Items.splice(index, 1);
      }
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
    DeleteCategory(category, categoriesInfo) {
      const index = categoriesInfo.indexOf(category);
      if (index > -1) {
        var item = this.modifications.find((x) => x.Id == category.Id);

        if (!item) {
          item = {
            Id: category.Id,
            Delete: true,
          };
          // eslint-disable-next-line vue/no-mutating-props
          this.modifications.push(item);
        } else {
          item.Delete = true;
        }

        categoriesInfo.splice(index, 1);
      }
    },
    ModifyCostItem(costItem, id) {
      var item = this.modifications.find((x) => x.Id === id);
      if (!item) {
        item = {
          Id: id,
          Items: {
            New: [],
            Modified: [costItem],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
        var index = item.Items.Modified.findIndex((x) => x.Id === costItem.Id);
        if (index > -1) item.Items.Modified[index] = costItem;
        else item.Items.Modified.push(costItem);
      }
    },
    SelectParametersItem(item) {
      this.$emit("selectedItem", item);
    },
    AddCostCategory(category) {
      var item = this.modifications.find((x) => x.Id === category.Id);
      if (!item) {
        item = {
          Id: category.Id,
          Name: category.Name,
          Blocked: category.Blocked,
          Items: {
            New: [],
            Modified: [],
            Deleted: [],
          },
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.modifications.push(item);
      } else {
        item.Name = category.Name;
        item.Blocked = category.Blocked;
        item.Items = item.Items || { New: [], Modified: [], Deleted: [] };
      }
    },
    nameChanged(category, event) {
      category.Name = event;
      this.AddCostCategory(category);
    },
    blockChanged(category, event) {
      category.Blocked = event;
      this.AddCostCategory(category);
    },
  },
  computed: {
    fields() {
      return {
        CostCategories: [
          {
            key: "Name",
            sortable: false,
            label: this.$t("fields.CostCategories.Item"),
          },
        ],
      };
    },
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.blockedInParent"),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
