<template>
  <div>
    <Panel
      id="parameters"
      :title="{ Name: 'Parameters', blocked: true }"
      :editable="false"
      :general="false"
    >
      <b-table
        :fields="fields"
        :items="parentParameters.concat(parameters)"
        outlined
        small
        select-mode="single"
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(parameterName)="row">
          <div>
            {{ row.item.parameterName }}
            <b-button
              v-if="
                !parentParameters.some(
                  (x) => x.idParameter == row.item.idParameter
                )
              "
              size="sm"
              @click="DeleteParameter(row.item)"
              variant="danger"
              class="rightBlock"
            >
              <b-icon-dash-square />
            </b-button>
            <b-icon
              v-else
              class="ml-2"
              vertical-align
              v-b-popover.hover.top="popupGeneralBlocked"
              icon="shield-lock-fill"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
      <b-button size="sm" v-b-modal="'add-parameter-modal'" variant="primary">
        <b-icon-plus-circle /> {{ $t("views.master-form.addItem") }}
      </b-button>
    </Panel>
    <AddParameterModal :idOrganizationalUnit="idOrganizationalUnit" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Panel from "@/components/panel";
import AddParameterModal from "@/components/MasterForm/AddParameter";
export default {
  components: {
    Panel,
    AddParameterModal,
  },
  methods: {
    ...mapActions("MasterForm", {
      deleteParameter: "deleteParameter",
    }),
    DeleteParameter({ idParameter }) {
      this.deleteParameter({ idParameter });
    },
    ShowMessage(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        appendToast: true,
      });
    },
  },
  computed: {
    ...mapGetters("MasterForm", [
      "parameters",
      "idOrganizationalUnit",
      "parentParameters",
    ]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Name"),
        },
      ];
    },
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.fromParent"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
