<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-row align-v="center">
        <b-button v-b-toggle="id" variant="white" class="ml-3 rightBlock">
          <span class="when-open">
            <b-icon-chevron-down />
          </span>
          <span class="when-closed">
            <b-icon-chevron-right />
          </span>
        </b-button>
        <b-col>
          <h6 class="h6 d-inline-block">{{ title }}</h6>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse :id="id" :accordion="accordion" role="tabpanel">
      <slot></slot>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    accordion: {
      required: false,
      type: String,
      default: "panel-accordion",
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
