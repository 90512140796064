<template>
  <b-modal
    id="summations-header-modal"
    :title="$t('fields.Summations.SummationsHeader')"
    ok-only
    @show="$forceUpdate()"
  >
    <b-list-group
      class="flex-column align-items-start"
      data-spy="scroll"
      style="max-width: 460px; max-height: 250px; overflow-y: auto"
    >
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
        button
        v-for="summationItem in generalItems.Summations"
        v-bind:key="summationItem.Id"
      >
        {{ summationItem.Name }}
        <div class="d-flex align-items-center">
          <b-checkbox v-model="summationItem.SummarySelected"> </b-checkbox>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
        button
        v-for="summationItem in items.Summations"
        v-bind:key="summationItem.Id"
      >
        {{ summationItem.Name }}
        <div class="d-flex align-items-center">
          <b-checkbox v-model="summationItem.SummarySelected"> </b-checkbox>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Object,
    },
    generalItems: {
      required: true,
      type: Object,
    },
    summationsHeader: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isActive: true,
      hasError: false,
    };
  },
  methods: {
    AddSummationHeader(summationInfo, event) {
      if (event) {
        summationInfo.Blocked = event;
        // eslint-disable-next-line vue/no-mutating-props
        this.summationsHeader.Summations[summationInfo.Id] =
          this.properties(summationInfo);
      } else {
        delete this.summationsHeader.Summations[summationInfo.Id];
      }
    },
    properties(summationInfo) {
      return {
        Id: summationInfo.Id,
        Name: summationInfo.Name,
        Cost: 0,
        ExternalCost: 0,
      };
    },
  },
};
</script>
