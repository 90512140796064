<template>
  <div>
    <b-modal
      ref="select-parameter"
      id="select-parameter-modal"
      :title="$t('views.parameters.title')"
      ok-only
    >
      <b-table
        :fields="fields"
        :items="parameters.concat(parentParameters)"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(parameterName)="data">
          <div>
            {{ data.value }}
            <b-checkbox
              :checked="
                item.Parameters.some(
                  (x) => x.idParameter == data.item.idParameter
                )
              "
              class="rightBlock"
              block
              size="sm"
              @change="changedValue($event, data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("MasterForm", ["parameters", "parentParameters"]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Name"),
        },
      ];
    },
  },
  methods: {
    changedValue(event, selected) {
      this.$emit("changed", event, selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
