<template>
  <div>
    <Panel
      id="parameters"
      :title="{ Name: 'Parameters', blocked: true }"
      :editable="false"
      :general="false"
    >
      <b-table
        :fields="fields"
        :items="parentParameters.concat(parameters)"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(parameterName)="row">
          <div>
            {{ row.item.parameterName }}
            <b-icon
              v-if="
                parentParameters.some(
                  (x) => x.idParameter == row.item.idParameter
                )
              "
              class="ml-2"
              vertical-align
              v-b-popover.hover.top="popupGeneralBlocked"
              icon="shield-lock-fill"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
    </Panel>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Panel from "@/components/panel";
export default {
  components: {
    Panel,
  },
  computed: {
    ...mapGetters("MasterForm", ["parameters", "parentParameters"]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Name"),
        },
      ];
    },
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.fromParent"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
