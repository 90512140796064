<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-row align-v="center">
        <b-button v-b-toggle="id" variant="white" class="ml-3 rightBlock">
          <span class="when-open">
            <b-icon-chevron-down />
          </span>
          <span class="when-closed">
            <b-icon-chevron-right />
          </span>
        </b-button>
        <b-col>
          <b-row align-v="center">
            <b-col cols="11">
              <b-form-input
                :disabled="input.systemBlocked || input.blocked"
                v-model="input.title"
                placeholder="Name"
                @change="$emit('renamed', input.title)"
              />
            </b-col>
            <b-col cols="1">
              <b-icon
                scale="1.5"
                v-b-popover.hover.top="popupGeneralBlocked"
                icon="shield-lock-fill"
                v-if="input.generalBlocked"
              />
              <b-button
                v-else
                :disabled="input.systemBlocked"
                :pressed.sync="input.blocked"
                @click="$emit('blocked', input.blocked)"
                variant="outline-primary"
                size="sm"
                ><b-icon
                  scale="1.4"
                  icon="lock-fill"
                  v-if="input.systemBlocked || input.blocked"
                />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse :id="id" :accordion="accordion" role="tabpanel">
      <slot></slot>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    blocked: {
      required: true,
      type: Boolean,
    },
    generalBlocked: {
      required: true,
      type: Boolean,
    },
    systemBlocked: {
      required: true,
    },
    accordion: {
      required: false,
      type: String,
      default: "panel-accordion",
    },
  },
  data() {
    return {
      input: {
        title: "",
        blocked: false,
        generalBlocked: false,
        systemBlocked: false,
      },
    };
  },
  computed: {
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.blockedInParent"),
      };
    },
  },
  mounted() {
    this.input = {
      title: this.title,
      blocked: this.blocked,
      generalBlocked: this.generalBlocked,
      systemBlocked: this.systemBlocked,
    };
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
